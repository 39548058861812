import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonIcon,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useEffect, useState } from "react";
import { ExpensesList } from "../components/ExpensesList";
import { supabase } from "../supabaseClient";
import { Expense } from "../types/Expense";

export function Expenses() {
  const [showLoading, hideLoading] = useIonLoading();
  const [showToast] = useIonToast();
  const [session] = useState(() => supabase.auth.session());
  const [expenses, setExpenses] = useState<Array<Expense> | null>(null);

  useEffect(() => {
    const getExpenses = async () => {
      await showLoading();
      try {
        const user = supabase.auth.user();
        let { data, error, status } = await supabase
          .from("expenses")
          .select("*")
          .eq("user_id", user!.id);
        if (error && status !== 406) {
          throw error;
        }
        if (data) {
          setExpenses(data);
        }
      } catch (error: any) {
        showToast({ message: error.message, duration: 5000 });
      } finally {
        await hideLoading();
      }
    };
    getExpenses();
    console.log(session);
  }, [session, hideLoading, showLoading, showToast]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Expense Tracker</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Expense Tracker</IonTitle>
          </IonToolbar>
        </IonHeader>

        <ExpensesList expensesList={expenses} />

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon icon={add} />
          </IonFabButton>
          <IonFabList side="top">
            <IonFabButton>
              <IonIcon icon={add} />
            </IonFabButton>
            <IonFabButton>
              <IonIcon icon={add} />
            </IonFabButton>
            <IonFabButton>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle>Footer</IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}
