import { IonList, IonItem, IonLabel } from "@ionic/react";
import { Expense } from "../types/Expense";

export function ExpensesList({
  expensesList,
}: {
  expensesList: Array<Expense> | null;
}) {
  return (
    <IonList>
      {expensesList?.map((expense, index) => (
        <IonItem key={index}>
          <IonLabel>{expense?.merchant.id}</IonLabel>
        </IonItem>
      ))}
    </IonList>
  );
}
