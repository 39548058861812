import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { supabase } from "./supabaseClient";

import "@ionic/react/css/ionic.bundle.css";

/* Theme variables */
import "./theme/variables.css";
import { LoginPage } from "./pages/Login";
import { AccountPage } from "./pages/Account";
import { useEffect, useState } from "react";
import { Session } from "@supabase/supabase-js";
import { Expenses } from "./pages/Expenses";
import {
  personCircleOutline,
  peopleCircleOutline,
  newspaperOutline,
  folderOutline,
} from "ionicons/icons";

setupIonicReact();

const App: React.FC = () => {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    setSession(supabase.auth.session());
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, [session]);
  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route
              exact
              path="/"
              render={() => {
                return session ? <Redirect to="/expenses" /> : <LoginPage />;
              }}
            />
            <Route exact path="/expenses">
              <Expenses />
            </Route>
            <Route exact path="/account">
              <AccountPage />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="expenses">
              <IonIcon icon={newspaperOutline} />
              <IonLabel>Expenses</IonLabel>
              {/* <IonBadge>6</IonBadge> */}
            </IonTabButton>

            <IonTabButton tab="reports">
              <IonIcon icon={folderOutline} />
              <IonLabel>Reports</IonLabel>
            </IonTabButton>

            <IonTabButton tab="merchants">
              <IonIcon icon={peopleCircleOutline} />
              <IonLabel>Merchants</IonLabel>
            </IonTabButton>

            <IonTabButton tab="account">
              <IonIcon icon={personCircleOutline} />
              <IonLabel>Profile</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
